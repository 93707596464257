import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import Timer from '../../components/timer'
import Controls from './controls/controls'
import Main from 'layout/main'
import useControls from '../../hooks/controls'
import { Training } from '../../interfaces/training'
import { useLocation } from 'react-router-dom'
import GroupWorkoutHeader from 'pages/group-workout/components/header'
import TrackList from './header/trackList'
import { useGroupWorkoutByIdQuery } from 'api/queries/group-workouts';
import { useCurrentGroupWorkout, useGroupWorkoutLoading, useGroupWorkoutError } from 'store/group-workout/selectors';
import { useGroupWorkoutSessionByIdQuery } from 'api/queries/group-workout-session';
import HitSimulate from 'components/hit-simulate/hit-simulate'
import useAddGroupHit from 'lib/hooks/useAddGroupHit'
import {
  useCurrentGroupWorkoutSession,
  useGroupWorkoutSessionActiveTrack,
  useGroupWorkoutSessionActiveRound,
  useGroupWorkoutSessionActions,
} from 'store/group-workout-session/selectors'
import GroupIntensity from 'components/group-workout/group-intensity'
import GroupHitChallenge from 'components/group-workout/group-hit-challenge'
import GroupGroupChallenge from 'components/group-workout/group-group-challenge'
import CircularTimer from 'components/group-workout/circular-timer'

const GroupWorkout: React.FC = () => {
  const location = useLocation()
  const activeTrack = useGroupWorkoutSessionActiveTrack()
  const activeRound = useGroupWorkoutSessionActiveRound()
  const { updateActiveRound, updateActiveTrack } = useGroupWorkoutSessionActions()
  const { id } = location.state as { id: string }
  const groupWorkoutSession =  useCurrentGroupWorkoutSession();
  //console.log(groupWorkoutSession);
  useGroupWorkoutSessionByIdQuery(id, {
    skip: !!groupWorkoutSession && groupWorkoutSession.id === id,
  });
  const groupWorkout =  useCurrentGroupWorkout();
  useGroupWorkoutByIdQuery(groupWorkoutSession?.groupWorkout?.id ?? "", {
    skip: !!groupWorkout && !!groupWorkoutSession && groupWorkout.id === groupWorkoutSession?.groupWorkout?.id,
  });
  // Access loading and error states from Zustand store via selectors
  const loading = useGroupWorkoutLoading();
  const error = useGroupWorkoutError();

  // Access groupWorkouts data from Zustand store via selectors
  const [rounds, setRounds] = useState<any[]>([])
  useAddGroupHit();

  // 3. We’ll store local countdown in roundTime
  const intervalRef = useRef<number | null>(null)

  const sessionTracks = groupWorkoutSession?.groupWorkoutSessionTracks ?? []
  const workoutTracks = groupWorkout?.groupWorkoutTracks ?? []
  const sessionTrack = sessionTracks[activeTrack]
  const workoutTrack = workoutTracks[activeTrack]
  //console.log("activeTrack", activeTrack)
  //console.log("activeRound", activeRound)
  //console.log("workoutTracks", workoutTracks)
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const sessionRound = sessionTrack?.groupWorkoutSessionRounds?.[activeRound]
  const workoutRound = workoutTrack?.groupWorkoutTrackRounds?.[activeRound]
  //console.log(workoutRound)
  const [roundTime, setRoundTime] = useState<number>(10)
  // Handles switch logic.
  const [currentSwitchIndex, setCurrentSwitchIndex] = useState<number>(0)
  const [switchTimeLeft, setSwitchTimeLeft] = useState<number>(0)
  const switchIntervalRef = useRef<number | null>(null)
  const switchInfo = workoutRound?.isSwitchRound
  ? {
      currentSwitch: currentSwitchIndex + 1,
      totalSwitches: workoutRound.totalSwitchRounds,
    }
  : null;

  useEffect(() => {
    if (workoutRound?.isSwitchRound && workoutRound?.timeInSwitchRound) {
      setSwitchTimeLeft(workoutRound.timeInSwitchRound); // Reset switch time
      setCurrentSwitchIndex(0); // Start from the first switch
    }
  }, [workoutRound]);
  

  useEffect(() => {
    if (workoutRound?.duration) {
      setRoundTime(workoutRound.duration)
    }
  }, [workoutRound?.duration])

  useEffect(() => {
    if (groupWorkout) {
      const newRounds = groupWorkout.groupWorkoutTracks
        .filter(track => track.groupWorkoutTrackRounds.length > 0 && !track.isPreWorkout)
        .flatMap(track =>
          track.groupWorkoutTrackRounds.map(round => ({
            id: round.id,
            name: round.name,
            duration: round.duration,
          }))
        );
  
      // Update only if the rounds actually changed
      setRounds(prevRounds => {
        const roundsChanged = JSON.stringify(prevRounds) !== JSON.stringify(newRounds);
        return roundsChanged ? newRounds : prevRounds;
      });
    }
  }, [loading, groupWorkout]);

  const { isPaused, handlePause } =
    useControls()

  const customHandlePause = () => {
    handlePause();
    if (isPaused) {
      audioRef.current?.play();
      videoRef.current?.play();
    } else {
      audioRef.current?.pause();
      videoRef.current?.pause();
    }
  };

  // Go to Next Round
  const customHandleNext = () => {
    if (!workoutTrack) return;

    const isLastRound = activeRound >= workoutTrack.groupWorkoutTrackRounds.length - 1;
    if (isLastRound) {
      const isLastTrack = activeTrack >= workoutTracks.length - 1;
      if (!isLastTrack) {
        updateActiveTrack(activeTrack + 1); // Move to the next track
        updateActiveRound(0); // Start from the first round in the new track
      }
    } else {
      updateActiveRound(activeRound + 1); // Move to the next round
    }
  };

  // Go to Previous Round
  const customHandlePrev = () => {
    if (activeRound > 0) {
      updateActiveRound(activeRound - 1); // Move to the previous round
    } else if (activeTrack > 1) {
      updateActiveTrack(activeTrack - 1); // Move to the previous track
      const previousTrack = workoutTracks[activeTrack - 1];
      const lastRoundIndex = previousTrack.groupWorkoutTrackRounds.length - 1;
      updateActiveRound(lastRoundIndex); // Set to the last round of the previous track
    }
  };
  const [training, createTraining] = useState<Training>()
  const [times, setTimes] = useState<number[]>([])
  const [totalTime, setTotalTime] = useState<number>(0)
  const trackRef = useRef<NodeJS.Timeout | null>(null)
  const timeRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    const handleTimeTick = () => {
      if (!isPaused) {
        setTotalTime((prevTotalTime) => {
          return prevTotalTime + 1
        })
      }
    }

    if (!isPaused) {
      timeRef.current = setInterval(handleTimeTick, 1000)
    } else if (timeRef.current) {
      clearInterval(timeRef.current)
    }

    return () => {
      if (timeRef.current) {
        clearInterval(timeRef.current)
      }
    }
  }, [isPaused])

  useEffect(() => {
    // Update the workoutRound and reset the timer whenever the active round or track changes
    if (workoutTrack && activeRound < workoutTrack.groupWorkoutTrackRounds?.length) {
      const newWorkoutRound = workoutTrack.groupWorkoutTrackRounds[activeRound];
      if (newWorkoutRound?.duration) {
        console.log("Resetting round time to", newWorkoutRound.duration);
        setRoundTime(newWorkoutRound.duration); // Reset the round timer
      }
    }
  }, [activeTrack, activeRound, workoutTrack]);


  useEffect(() => {
    if (!workoutRound?.isSwitchRound || isPaused) {
      if (switchIntervalRef.current) clearInterval(switchIntervalRef.current);
      return;
    }
  
    // Function to reset the switch timer
    const resetSwitchTimer = (nextSwitchIndex: number) => {
      setCurrentSwitchIndex(nextSwitchIndex);
      setSwitchTimeLeft(workoutRound.timeInSwitchRound); // Reset the timer for the next switch
    };
  
    switchIntervalRef.current = window.setInterval(() => {
      setSwitchTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(switchIntervalRef.current!); // Clear the interval when time reaches 0
  
          if (currentSwitchIndex < workoutRound.totalSwitchRounds - 1) {
            // Move to the next switch round
            resetSwitchTimer(currentSwitchIndex + 1);
          } else {
            // Handle the end of all switch rounds
            console.log("All switches in this round are complete.");
            setSwitchTimeLeft(0); // Ensure the timer shows 0 after the last switch
          }
  
          return workoutRound.timeInSwitchRound; // Reset time for the next round
        }
  
        return Math.max(prevTime - 1, 0); // Decrement time
      });
    }, 1000);
  
    return () => {
      if (switchIntervalRef.current) clearInterval(switchIntervalRef.current);
    };
  }, [workoutRound, currentSwitchIndex, isPaused]);

  useEffect(() => {
  const handleRoundComplete = () => {
    if (!workoutTrack) return;

    const totalRounds = workoutTrack.groupWorkoutTrackRounds?.length ?? 0;
    if (totalRounds === 0) return;

    const isLastRound = activeRound >= totalRounds - 1;
    const isPreOrPostWorkout = workoutTrack.isPreWorkout || workoutTrack.isPostWorkout;

    if (isPreOrPostWorkout) {
      // Loop within the same track
      console.log("Looping within pre/post workout");
      updateActiveRound(isLastRound ? 0 : activeRound + 1);
    } else {
      const isLastTrack = activeTrack >= workoutTracks.length - 1;
      if (isLastRound) {
        if (!isLastTrack) {
          console.log("Moving to the next track");
          updateActiveTrack(activeTrack + 1); // Move to the next track
          updateActiveRound(0); // Reset to the first round
        } else {
          console.log("Workout complete!");
        }
      } else {
        console.log("Moving to the next round");
        updateActiveRound(activeRound + 1); // Move to the next round
      }
    }
  };

  if (!workoutTrack) return;

  if (intervalRef.current) {
    clearInterval(intervalRef.current);
  }
  if (!workoutTrack || isPaused) {
    if (intervalRef.current) 
    {
      clearInterval(intervalRef.current);
    }
    return;
  }

  intervalRef.current = window.setInterval(() => {
    setRoundTime((prevTime) => {
      if (prevTime === 1) {
        clearInterval(intervalRef.current!); // Stop the timer
        handleRoundComplete(); // Handle round completion
        return 0; // Ensure the timer reaches 0
      }
      return Math.max(prevTime - 1, 0); // Decrement the timer
    });
  }, 1000);

  // Cleanup the interval on unmount or re-run
  return () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };
}, [workoutTrack, activeRound, activeTrack, isPaused]);

  // Play Audio on Component Load
  useEffect(() => {
    if (audioRef.current && groupWorkout?.audioFile?.blobUrl) {
      audioRef.current.play().catch((err) => {
        console.error("Failed to autoplay audio:", err);
      });
    }
  }, [groupWorkout]);

  return (
    <Main>
      <div className="workout-new">
          <GroupWorkoutHeader>
            <TrackList rounds={rounds || []} currentRound={rounds[0]} />
          </GroupWorkoutHeader>
           {/* <Header
            title={getCurrentTrackTitle()}
            time={getCurrentTrackTime()}
            totalTime={totalTime}
            tracks={training.tracks}
            activeTrackId={trackIndex}
          />
         <Timer duration={parseInt(getCurrentTrackTime())} /> */}
                 {/* Video for the current round */}
        {groupWorkout?.audioFile?.blobUrl ? (
          <audio ref={audioRef} src={groupWorkout.audioFile.blobUrl} autoPlay loop controls className="hidden-audio">
            Your browser does not support the audio element.
          </audio>
        ) : (
          <p>Loading audio...</p>
        )}
        {workoutRound?.videoFile?.blobUrl ? (
          <div className="video-wrapper">
            <video
              ref={videoRef}
              id={`video-${workoutRound.id}`}
              src={workoutRound.videoFile.blobUrl}
              autoPlay
              loop
              muted
              playsInline
              className="fullscreen-video"
            />
          </div>
        ) : (
          <p>Loading video...</p>
        )}
        {workoutRound?.showIntensity && sessionRound && (
        <div className="intensity-wrapper">
          <GroupIntensity
            groupBags={groupWorkoutSession?.groupBags || []}
            groupWorkoutHits={sessionRound.groupWorkoutHits}
            exerciseSecond={workoutRound.duration - roundTime}
          />
        </div>
        )}

      {workoutRound?.showHitScore && sessionRound && (
        <div className="group-hit-challenge-wrapper">
          <GroupHitChallenge
            groupBags={groupWorkoutSession?.groupBags || []}
            groupWorkoutHits={sessionRound.groupWorkoutHits}
          />
        </div>
        )}

      {workoutRound?.showGroupChallenge && sessionRound && (
        <div className="group-group-challenge-wrapper">
          <GroupGroupChallenge
            groupBags={groupWorkoutSession?.groupBags || []}
            groupWorkoutHits={sessionRound.groupWorkoutHits}
          />
        </div>
        )}

        {workoutTrack ? (
          <div className="round-info">
            <h2>
              {workoutTrack.name}
              {workoutTrack.isPreWorkout && ' (Pre-Workout)'}
              {workoutTrack.isPostWorkout && ' (Post-Workout)'}
            </h2>
            <h3>Round: {workoutRound?.name}</h3>
            <p>Time Remaining: {roundTime}</p>
            <CircularTimer
              roundDuration={workoutRound.duration}
              timeLeftInRound={roundTime}
              isPaused={isPaused}
              showSwitch={false}
            />
            {workoutRound?.isSwitchRound && switchInfo && (
            <div className="switch-timer">
                <h3>Switch {currentSwitchIndex + 1} of {workoutRound.totalSwitchRounds}</h3>
                <p>Time Remaining in Switch: {switchTimeLeft}</p>
                <CircularTimer
                  roundDuration={workoutRound.duration}
                  timeLeftInRound={roundTime}
                  timeLeftInSwitch={switchTimeLeft}
                  switchDuration={workoutRound.timeInSwitchRound}
                  isPaused={isPaused}
                  showSwitch={true}
                  switchInfo={{ currentSwitchRound: switchInfo.currentSwitch, totalSwitches: switchInfo.totalSwitches }}
                />
              </div>
            )}
          </div>
        ) : (
          <p>Loading or no track data...</p>
        )}
       

        <HitSimulate />
        <Controls handlePause={customHandlePause} handlePrev={customHandlePrev} handleNext={customHandleNext} />
      </div>
    </Main>
  )
}

export default GroupWorkout
