import React from 'react'
import './style.scss'
import { useNavigate } from 'react-router-dom';

interface ControlsProps {
  handlePause: () => void;
  handlePrev: () => void;
  handleNext: () => void;
}

const Controls: React.FC<ControlsProps> = ({handlePause, handlePrev, handleNext}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/new-home');
  }

  return (
    <div className="controls">
      <button onClick={handleClick} className="button">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
        </svg>
      </button>
      <button className="button backward" onClick={handlePrev}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M11 19V5l-7 7m8-7v14l7-7" />
        </svg>
      </button>
      <button className="button main" onClick={handlePause}>
        PAUSE
        <br />
        STOP
      </button>
      <button className="button forward" onClick={handleNext}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 5v14l7-7m-8 7V5L5 12" />
        </svg>
      </button>
      <button className="button volume">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 10v4h4l5 5V5L7 10H3zm13.5 2c0-1.77-.9-3.29-2.25-4.15v8.3A4.982 4.982 0 0016.5 12zm2.5 0c0 2.5-1.28 4.64-3.22 5.82l1.43 1.43C19.24 17.46 21 14.92 21 12s-1.76-5.46-4.79-7.25l-1.43 1.43C17.72 7.36 19 9.5 19 12z" />
        </svg>
      </button>
    </div>
  )
}

export default Controls
