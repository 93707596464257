import React from 'react';
import { INRWorkoutSessionHit } from 'interfaces/workout'
import { GroupWorkoutHit, GroupBag } from 'interfaces/group-workout'

// Props for GroupIntensity component
interface GroupHitChallengeProps {
  groupBags: GroupBag[];
  groupWorkoutHits: GroupWorkoutHit[];
}

// GroupIntensity component
const GroupHitChallenge: React.FC<GroupHitChallengeProps> = ({ groupBags, groupWorkoutHits }) => {
  const hitsByBag = groupWorkoutHits.reduce((acc, hit) => {
    const bagId = hit.bag.id;
    if (!acc[bagId]) {
      acc[bagId] = [];
    }
    const sessionHit: INRWorkoutSessionHit = {
      angle: 0, // Default value
      force: hit.force,
      height: 0, // Default value
      punchedAt: hit.punchedAt,
    };

    acc[bagId].push(sessionHit);
    return acc;
  }, {} as Record<string, INRWorkoutSessionHit[]>);
  // Calculate intensity for each bag
  const bagIntensities = groupBags.map((groupBag) => {
    const bagId = groupBag.bag.id;
    const hitsForBag = hitsByBag[bagId] || [];
    return {
      bagId,
      hitsCount: hitsForBag.length,
    };
  });

  return (
    <div>
      <h2>Hits challenge</h2>
      <ul>
        {bagIntensities.map(({ bagId, hitsCount }) => (
          <li key={bagId}>
            <strong>Bag ID:</strong> {bagId} <br />
            <strong>Hits Count:</strong> {hitsCount}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GroupHitChallenge;
