import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Main from 'layout/main';
import GroupWorkoutHeader from 'pages/group-workout/components/header';
import './style.scss'
import { GroupWorkoutTrackRound } from 'interfaces/group-workout'
import { useNavigate } from 'react-router-dom'
import { useGroupWorkoutByIdQuery } from 'api/queries/group-workouts';
import { useCurrentGroupWorkout, useGroupWorkoutLoading, useGroupWorkoutError } from 'store/group-workout/selectors';
import { useGroupWorkoutSessionByIdQuery } from 'api/queries/group-workout-session';
import { useCurrentGroupWorkoutSession, useGroupWorkoutSessionActions } from 'store/group-workout-session/selectors';

const GroupPreWorkout: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const { updateActiveRound, updateActiveTrack } = useGroupWorkoutSessionActions()
  // Group workout session id.
  const { id } = location.state as { id: string };
  const groupWorkoutSession =  useCurrentGroupWorkoutSession();
  useGroupWorkoutSessionByIdQuery(id, {
    skip: !!groupWorkoutSession && groupWorkoutSession.id === id,
  });
  const groupWorkout =  useCurrentGroupWorkout();
  useGroupWorkoutByIdQuery(groupWorkoutSession?.groupWorkout?.id ?? "", {
    skip: !!groupWorkout && !!groupWorkoutSession && groupWorkout.id === groupWorkoutSession?.groupWorkout?.id,
  });

  // Access loading and error states from Zustand store via selectors
  const loading = useGroupWorkoutLoading();
  const error = useGroupWorkoutError();

  const [trackRounds, setTrackRounds] = useState<GroupWorkoutTrackRound[]>([]);

  useEffect(() => {
    if (!loading && groupWorkout?.groupWorkoutTracks?.length) {
      const firstTrack = groupWorkout.groupWorkoutTracks[0];
      setTrackRounds(firstTrack.groupWorkoutTrackRounds);
    }
  }, [loading, groupWorkout]);

  function startWorkout() {
    updateActiveTrack(1)
    updateActiveRound(0)
    navigate(`/group-workout/workout/${id}`, { state: { id } })
  }

  // Check if first track is a preworkout
  const hasPreWorkout = groupWorkout?.groupWorkoutTracks?.[0].isPreWorkout;

  return (
    <Main>
      <GroupWorkoutHeader>
        <div className="group-workout-title">Get ready to sweat!</div>
      </GroupWorkoutHeader>
      <div className="group-workout-home">
        {hasPreWorkout ? (
          <div className="preworkout-section">
            {trackRounds.map((round) => {
              const videoFile = round.videoFile;
              return (
                <div key={round.id} className="round-container">
                  {videoFile?.blobUrl ? (
                    <div className="video-wrapper">
                      <video
                        id={`video-${round.id}`}
                        src={videoFile?.blobUrl}
                        autoPlay
                        loop
                        muted
                        playsInline
                        className="fullscreen-video"
                      />
                    </div>
                  ) : (
                    <p>Loading video...</p>
                  )}
                </div>
              );
            })}
             <button
              onClick={() => startWorkout()}
              className="start-workout-button"
            >
              Start workout
            </button>
          </div>
        ) : (
          <div>
            <p>
              The first track of a group workout should be a preworkout track and
              contain a round with a video.
            </p>
          </div>
        )}
      </div>
    </Main>
  );
};

export default GroupPreWorkout;
