import React, { useEffect, useState, useRef } from "react";
import "./circular-timer.scss";

interface CircularTimerProps {
  roundDuration: number; // Total duration of the round in seconds
  timeLeftInRound: number; // Remaining time in the round in seconds
  switchDuration?: number; // Total duration of the switch in seconds
  timeLeftInSwitch?: number; // Remaining time in the switch in seconds
  isPaused: boolean; // Pause state
  size?: "small" | "medium" | "large" | number; // Timer size
  showSwitch?: boolean; // Show switch info
  switchInfo?: { currentSwitchRound: number; totalSwitches: number }; // Switch details
  variant?: "fullscreen" | "quote" | "intensity" | "hitGroup"; // Variant type
}

const CircularTimer: React.FC<CircularTimerProps> = ({
  roundDuration,
  timeLeftInRound,
  switchDuration,
  timeLeftInSwitch,
  isPaused,
  size = "medium",
  showSwitch = false,
  switchInfo,
  variant,
}) => {
  const setTimeLeftInSwitch = timeLeftInSwitch ? timeLeftInSwitch : 0;
  const setSwitchDuration = switchDuration ? switchDuration : 0;
  const [roundMsLeft, setRoundMsLeft] = useState(timeLeftInRound * 1000);
  const [switchMsLeft, setSwitchMsLeft] = useState(setTimeLeftInSwitch * 1000);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const sizeMap = { small: 150, medium: 200, large: 300 };
  const sizeMapProgressBar = { small: 80, medium: 120, large: 200};
  const timerSize = typeof size === "number" ? size : sizeMap[size];
  const progressBarSize = typeof size === "number" ? size : sizeMapProgressBar[size];
  const radius = timerSize / 2 - 10;
  const strokeWidth = 10;
  const circumference = 2 * Math.PI * radius;

  const calculateOffset = (msLeft: number, totalDuration: number) => {
    const progress = 1 - msLeft / (totalDuration * 1000);
    return progress * circumference;
  };

  const [progressBarWidth, setProgressBarWidth] = useState(100);
  useEffect(() => {
    const newWidth = ((roundMsLeft / (roundDuration * 1000)) * 100).toFixed(2);
    setProgressBarWidth(parseFloat(newWidth)); // Update the progress dynamically
  }, [roundMsLeft, roundDuration]);

  console.log("Progress Bar Width:", progressBarWidth);

  useEffect(() => {
    if (!isPaused) {
      intervalRef.current = setInterval(() => {
        setRoundMsLeft((prev) => {
          if (prev <= 10) {
            clearInterval(intervalRef.current!);
            return 0;
          }
          return prev - 10;
        });

        setSwitchMsLeft((prev) => {
          if (prev <= 10) {
            return 0;
          }
          return prev - 10;
        });
      }, 10);
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [isPaused]);

  useEffect(() => {
    setRoundMsLeft(timeLeftInRound * 1000);
    setSwitchMsLeft(setTimeLeftInSwitch * 1000);
  }, [timeLeftInRound, timeLeftInSwitch]);

  const formatTime = (msLeft: number) => {
    const seconds = Math.floor(msLeft / 1000).toString().padStart(2, "0");
    const milliseconds = Math.floor((msLeft % 1000) / 10)
      .toString()
      .padStart(2, "0");
    return `${seconds}:${milliseconds}`;
  };

  return (
    <div
      className={`circular-timer ${variant}`}
      style={{ width: timerSize, height: timerSize }}
    >
      <div className="svg-circle">
        <svg width={timerSize} height={timerSize} viewBox={`0 0 ${timerSize} ${timerSize}`}>
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#20ebec" />
              <stop offset="100%" stopColor="#ffbd2c" />
            </linearGradient>
          </defs>
          <circle
            className="circle-bg"
            cx={timerSize / 2}
            cy={timerSize / 2}
            r={radius}
            strokeWidth={strokeWidth + 6}
          />
          {/* Round Progress */}
          {!showSwitch && (
          <circle
            className="circle-progress round-progress"
            cx={timerSize / 2}
            cy={timerSize / 2}
            r={radius}
            strokeWidth={strokeWidth}
            strokeDasharray={circumference}
            strokeDashoffset={calculateOffset(roundMsLeft, roundDuration)}
            stroke="url(#gradient)"
          />
          )}
          {/* Switch Progress */}
          {showSwitch && (
            <circle
              className="circle-progress round-progress"
              cx={timerSize / 2}
              cy={timerSize / 2}
              r={radius}
              strokeWidth={strokeWidth}
              strokeDasharray={circumference}
              strokeDashoffset={calculateOffset(switchMsLeft, setSwitchDuration)}
              stroke="url(#gradient)"  
            />
          )}
        </svg>
      </div>
      <div className="timer-content">
        {!showSwitch &&
          <h1 style={{ fontSize: timerSize / 5 }}>{formatTime(roundMsLeft)}</h1>
        }
        {showSwitch && (
          <div className="switch-info">
            <h1 style={{ fontSize: timerSize / 5 }}>{formatTime(switchMsLeft)}</h1>
            {switchInfo && (
              <div>
                <svg
                  className="progress-bar-svg"
                  width={progressBarSize} // Full width of the bar
                  height="5" // Fixed height
                  viewBox={`0 0 ${progressBarSize} 5`} // Define the coordinate system
                >
                  <defs>
                    <linearGradient id="progress-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                      <stop offset="0%" stopColor="#ffbd2c" /> 
                      <stop offset="100%" stopColor="#20ebec" /> 
                    </linearGradient>
                    <clipPath id="progress-clip">
                      <rect
                        x="0"
                        y="0"
                        width={(progressBarSize * progressBarWidth) / 100} // Adjust width for progress
                        height="10"
                      />
                    </clipPath>
                  </defs>

                  <rect
                    x="0"
                    y="0"
                    width={progressBarSize}
                    height="5"
                    fill="#333" // Background color for the empty portion
                  />

                  <rect
                    x="0"
                    y="0"
                    width={progressBarSize} // Dynamically adjust width
                    height="5"
                    fill="url(#progress-gradient)" // Apply the gradient
                    clipPath="url(#progress-clip)"
                  />
                </svg>
                <span className="current-round">{switchInfo.currentSwitchRound}</span>/
                <span className="total-rounds">{switchInfo.totalSwitches}</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CircularTimer;
