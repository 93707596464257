import React, { useState, useEffect } from 'react'
import './style.scss'

const TimeDisplay = () => {
  const [currentTime, setCurrentTime] = useState('')
  const [currentDay, setCurrentDay] = useState('')

  const updateTime = () => {
    const now = new Date()
    const hours = formatTime(now.getHours())
    const minutes = formatTime(now.getMinutes())
    setCurrentTime(`${hours}:${minutes}`)

    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    setCurrentDay(daysOfWeek[now.getDay()])
  }

  const formatTime = (time: number) => {
    return String(time).padStart(2, '0')
  }

  useEffect(() => {
    updateTime()
    const intervalId = setInterval(updateTime, 10000)
    return () => clearInterval(intervalId)
  }, [])

  return (
    <div className='time-display'>
      <div style={{ fontSize: '1rem' }}>{currentTime.toUpperCase()}</div>
      <h5 style={{ fontSize: '1.5rem' }}>{currentDay.toUpperCase()}</h5>
    </div>
  )
}

export default TimeDisplay
