// src/pages/group-workout/pages/group-workouts.tsx
import React from 'react';
import GroupWorkoutHeader from 'pages/group-workout/components/header';
import GroupWorkoutFooter from 'pages/group-workout/components/footer';
import Icon, { IconNames } from 'components/icons/icons';
import { useGroupWorkoutsQuery } from 'api/queries/group-workouts';
import { useGroupWorkouts, useGroupWorkoutLoading, useGroupWorkoutError } from 'store/group-workout/selectors';

import './style.scss';

interface GroupWorkoutsProps {
  navigateTo: (id: string) => void;
}

const GroupWorkoutsPage: React.FC<GroupWorkoutsProps> = ({ navigateTo }) => {
  // Trigger the query by invoking the custom hook
  useGroupWorkoutsQuery();

  // Access loading and error states from Zustand store via selectors
  const loading = useGroupWorkoutLoading();
  const error = useGroupWorkoutError();

  // Access groupWorkouts data from Zustand store via selectors
  const groupWorkouts = useGroupWorkouts();

  return (
    <div className="group-workout-home">
      <GroupWorkoutHeader>
        <div className="group-workout-title">WORKOUTS & CHALLENGES</div>
      </GroupWorkoutHeader>

      {/* Handle Loading State */}
      {loading && <p className="center-text">Loading...</p>}

      {/* Handle Error State */}
      {error && <p className="center-text">Error: {error}</p>}

      {/* Handle No Workouts Available */}
      {!loading && groupWorkouts.length === 0 && (
        <p className="center-text">No workouts available</p>
      )}

      {/* Display Group Workouts */}
      {groupWorkouts && groupWorkouts.length > 0 && (
        <div className="workouts-body">
          <div className="workout-list">
            {groupWorkouts.map((groupWorkout) => (
              <div
                className="workout-tile"
                key={groupWorkout.id}
                onClick={() => navigateTo(groupWorkout.id)}
              >
                <div className="img-container">
                  <img
                    src={
                      groupWorkout.thumbnailFile.blobUrl ||
                      'https://via.placeholder.com/300x150'
                    }
                    alt={groupWorkout.name}
                  />
                </div>
                <div className="workout-meta">
                  <div className="left">
                    <Icon name={IconNames.CLOCK} size={14} />
                  </div>
                  <div className="right">
                    <div style={{ paddingRight: '4px' }}>
                      <Icon name={IconNames.CLOCK} size={14} />
                    </div>
                    <div>{groupWorkout.duration} min.</div>
                  </div>
                </div>

                <div className="workout-info">
                  <h2>{groupWorkout.name}</h2>
                  <p>{groupWorkout.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <GroupWorkoutFooter />
    </div>
  );
};

export default GroupWorkoutsPage;
