// src/stores/groupWorkout/index.tsx
import { create } from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { GroupWorkout } from 'interfaces/group-workout';

export interface GroupWorkoutStore {
  groupWorkouts: GroupWorkout[];
  currentGroupWorkout: GroupWorkout | null;
  loading: boolean;
  error: string | null;
  actions: {
    setGroupWorkouts: (workouts: GroupWorkout[]) => void;
    setGroupWorkoutById: (workout: GroupWorkout) => void;
    addGroupWorkout: (workout: GroupWorkout) => void;
    updateGroupWorkout: (workout: GroupWorkout) => void;
    deleteGroupWorkout: (id: string) => void;
    clearCurrentGroupWorkout: () => void;
    setLoading: (loading: boolean) => void;
    setError: (error: string | null) => void;
  };
}

const useGroupWorkoutStore = create<GroupWorkoutStore>((set) => ({
  groupWorkouts: [],
  currentGroupWorkout: null,
  loading: false,
  error: null,
  actions: {
    setGroupWorkouts: (workouts: GroupWorkout[]) => set({ groupWorkouts: workouts }),
    setGroupWorkoutById: (workout: GroupWorkout) => set({ currentGroupWorkout: workout }),
    addGroupWorkout: (workout: GroupWorkout) =>
      set((state) => ({ groupWorkouts: [...state.groupWorkouts, workout] })),
    updateGroupWorkout: (workout: GroupWorkout) =>
      set((state) => ({
        groupWorkouts: state.groupWorkouts.map((gw) =>
          gw.id === workout.id ? workout : gw
        ),
        currentGroupWorkout:
          state.currentGroupWorkout?.id === workout.id ? workout : state.currentGroupWorkout,
      })),
    deleteGroupWorkout: (id: string) =>
      set((state) => ({
        groupWorkouts: state.groupWorkouts.filter((gw) => gw.id !== id),
        currentGroupWorkout:
          state.currentGroupWorkout?.id === id ? null : state.currentGroupWorkout,
      })),
    clearCurrentGroupWorkout: () => set({ currentGroupWorkout: null }),
    setLoading: (loading: boolean) => set({ loading }),
    setError: (error: string | null) => set({ error }),
  },
}));

// Enable Zustand devtools in development
if (process.env.NODE_ENV === 'development') {
  mountStoreDevtool('GroupWorkoutStore', useGroupWorkoutStore);
}

export default useGroupWorkoutStore;
export type { GroupWorkout };
