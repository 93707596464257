import React from 'react';
import { INRWorkoutSessionHit } from 'interfaces/workout'
import { GroupWorkoutHit, GroupBag } from 'interfaces/group-workout'
import { getEvaluatedIntensity, getHitsLastTenSeconds } from 'lib/intensity'

// Props for GroupIntensity component
interface GroupIntensityProps {
  groupBags: GroupBag[];
  groupWorkoutHits: GroupWorkoutHit[];
  weight?: number;
  exerciseSecond: number;
}

// GroupIntensity component
const GroupIntensity: React.FC<GroupIntensityProps> = ({ groupBags, groupWorkoutHits, weight = 75, exerciseSecond }) => {
  const hitsByBag = groupWorkoutHits.reduce((acc, hit) => {
    const bagId = hit.bag.id;
    if (!acc[bagId]) {
      acc[bagId] = [];
    }
    const sessionHit: INRWorkoutSessionHit = {
      angle: 0, // Default value
      force: hit.force,
      height: 0, // Default value
      punchedAt: hit.punchedAt,
    };

    acc[bagId].push(sessionHit);
    return acc;
  }, {} as Record<string, INRWorkoutSessionHit[]>);
  // Calculate intensity for each bag
  const bagIntensities = groupBags.map((groupBag) => {
    const bagId = groupBag.bag.id;
    const hitsForBag = hitsByBag[bagId] || [];
    const intensity = getEvaluatedIntensity(hitsForBag, weight, exerciseSecond);
    return {
      bagId,
      intensity,
      hitsCount: hitsForBag.length,
    };
  });

  return (
    <div>
      <h2>Group Intensity</h2>
      <ul>
        {bagIntensities.map(({ bagId, intensity, hitsCount }) => (
          <li key={bagId}>
            <strong>Bag ID:</strong> {bagId} <br />
            <strong>Intensity:</strong> {intensity} <br />
            <strong>Hits Count:</strong> {hitsCount}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default GroupIntensity;
