import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Main from 'layout/main';
import GroupWorkoutHeader from 'pages/group-workout/components/header';
import './style.scss';
import { useGroupWorkoutByIdQuery } from 'api/queries/group-workouts';
import { useCurrentGroupWorkout, useGroupWorkoutLoading, useGroupWorkoutError } from 'store/group-workout/selectors';
import { useCreateGroupWorkoutSession } from 'api/mutations/group-workout-session';
import { useCurrentGroupWorkoutSession, useGroupWorkoutSessionActions } from 'store/group-workout-session/selectors';

const GroupWorkoutSetupNewWorkout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state as { id: string };
  useGroupWorkoutByIdQuery(id);
  // Access loading and error states from Zustand store via selectors
  const loading = useGroupWorkoutLoading();
  const error = useGroupWorkoutError();
  const hasSessionBeenCreated = useRef(false);
  // Access groupWorkouts data from Zustand store via selectors
  const groupWorkout =  useCurrentGroupWorkout();
  const groupWorkoutSession =  useCurrentGroupWorkoutSession();
  const { createGroupWorkoutSession } = useCreateGroupWorkoutSession();
  const { toggleGroupBagEnabled } = useGroupWorkoutSessionActions()
  const handleCreateSession = async () => {
    try {
      if (groupWorkout) {
        await createGroupWorkoutSession(groupWorkout.id);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!loading && groupWorkout && !hasSessionBeenCreated.current) {
      hasSessionBeenCreated.current = true;
      handleCreateSession();
    }
  });

  const startPreWorkout = () => {
    if (groupWorkoutSession?.id) {
      navigate(`/group-workout/pre-workout/${groupWorkoutSession?.id}`, { state: { id: groupWorkoutSession?.id } });
    }
  };

  return (
    <Main>
      <GroupWorkoutHeader>
        <div className="group-workout-title">Setup Workout</div>
      </GroupWorkoutHeader>

      {loading ? (
        <div className="loading">Loading...</div>
      ) : groupWorkout ? (
        <div className="group-workout-setup">
          <section className="setup-instructions">
            <p>This page is for the instructor to set up the group workout.</p>
            <ul>
              <li>Select boxing bags that join the workout.</li>
              <li>Fetch bags using the tablet API key.</li>
              <li>List all bags and enable them by default.</li>
              <li>Allow enabling or disabling bags via the tablet.</li>
              <li>Save the configuration in a group workout session.</li>
              <li>
                Perform an extra check on connection info (may need to restart the bag if it is offline).
              </li>
            </ul>
          </section>

          {groupWorkoutSession?.groupBags?.map((groupBag) => (
            <div key={groupBag.id} className="bag-select-checkbox">
              <input
                
                type="checkbox"
                checked={groupBag.isEnabled}
                onChange={() => toggleGroupBagEnabled(groupBag.id)}
              />
              {groupBag.bag.name} (id: {groupBag.bag.id})
            </div>
          ))}
          <button
            onClick={() => startPreWorkout()}
            className="start-workout-button"
          >
            Start Pre-Workout
          </button>
        </div>
      ) : (
        <div className="error-message">
          <p>
            The group workout is not found or there was a problem loading it. Please try again later.
          </p>
        </div>
      )}
    </Main>
  );
};

export default GroupWorkoutSetupNewWorkout;
