import React from 'react'
import './style.scss'
import Icon, { IconNames } from 'components/icons/icons'
import TimeDisplay from '../time-display'
import ConnectionIndicator from 'components/connection-indicator/connection-indicator'

const GroupWorkoutHeader: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="group-workout-header">
      <div className="group-workout-logo">
        <Icon name={IconNames.LOGO_SMALL_BL} size={65} />
        <TimeDisplay />
      </div>

      {children}

      <div>
        <ConnectionIndicator />
      </div>
    </div>
  )
}

export default GroupWorkoutHeader
